.Container{
    /*width: 500px;
    height: 600px;*/
    width: 100%;
    height: 100svh;
    max-width: 500px;
    max-height: 5000px;

    background-color: #AFB2D2;
}
body {
    margin: 0;
    padding: 0;
    font-size: 10px;
  }