.button_input{
    width: 20%;
    height: 100%;
    /*padding-left: 1%;*/
    border: none;
    background-color: transparent;
    cursor: pointer;
    align-items:center;
    /*font-size: 70%;*/
    font-size: clamp(0.7em, 0.7em + 1.2vw, 1.5em);
    
}
.buttonMore{
    border: none;
    cursor: pointer;
    background-color: transparent;
    margin-top: 0.7em;
    padding: 0;
    padding-right: 2.5em;
}

.buttonMore img{
    width: clamp(1em, 1em + 3.5vw, 2.7em);
    height: clamp(1em, 1em + 3.5vw, 2.7em)
}

