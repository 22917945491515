.PayToWho {
    margin-top: 0px;
    border-radius: 10px;
    max-height: 98%;
    overflow-y: auto;
    height: 81%;
    user-select: none;
}

.PayToWho table{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    user-select: none;
    width: 98%;
    min-height: 20%;
    border-radius: 10px;
    border: 1px solid black;
    border-collapse: inherit;
    margin: 1%;
}

.PayToWho thead{
    align-content: center;
    text-align: center;
    /*font-size: 16px;*/
    border:1px;
    font-size: clamp(0.5em, 0.5em + 2vw, 1.5em);
}

.PayToWho tbody{
    align-content: center;
    text-align: center;
    border-bottom: 1px;
    border: 1px;
    /*font-size: 15px;*/
    font-size: clamp(0.5em, 0.5em + 2vw, 1.5em);
    border-bottom: 1px solid #000;
}

.PayToWho p{
    text-align: center;
    
}

