.div_main{
    height: 82%;
    max-height: 82%;
    display: flex;
    flex-direction: column;
}
.div_main_event{
    height: 86%;
    max-height: 86%;
    display: flex;
    flex-direction: column;
}
.div_input{
    
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.50);
    background: #BFABAB;
    padding: 5px;
    padding-top: 10px;
    /*padding-bottom: 10px;*/
    margin: 8px;
    margin-bottom: 0;
    /*padding: 2%;
    margin: 1.5%;*/
    font-family: Arial, Helvetica, sans-serif;
    /*font-size: 20px;*/    
}
.div_input_insert{
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.50);
    background: #BFABAB;
    padding: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 8px;
    margin-bottom: 0;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;  
    display: flex;  
}

.div_input input{
    width: 75%;
    border-radius: 10px;
    border: 1px solid #000;
    background: #C8C7DB;;
    height: 35px;
    padding: 5px;
    margin-left: 1%;
    font-size: 2em;
    /*font-size: 20px;*/
}
.div_input ul{
    max-height: 200px;
    overflow-y: auto;
   
}
.div_input div{
    display: flex;
    text-align: left;
    
}
.div_input ul div{
    border-bottom: 1px solid #000;
    align-items: center;
        
}
.div_input li{
    width: 80%;
    list-style-type:square;
    
}
.div_input p{
    text-align: center;
    /*font-size: 25px;*/
    /*font-size: clamp(1em, 1em + 1vw, 1.25em);*/
    font-size: clamp(1em, 1em + 2.8vw, 2.25em);
    margin: 0;

}
.divBodyExpenses{
    margin-top: 0px;
    border-radius: 10px;
    max-height: 98%;
    overflow-y: auto;
    height: 79%;
    user-select: none;
}
.startMsg {
    align-items: center;
    text-align: center;
    align-content: center;
    margin-top: 43%;
    margin-bottom: 43%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: clamp(0.5em, 0.5em + 1.5vw, 1.2em);
}

.divInputParticipants{
    display: flex;
    flex-direction: column;
    
}
.textInputParticipantsExpenes{
    align-items: start;
  
}
.textInputParticipantsExpenes p{
    font-size: 13px;
    width: 50%;
    margin-left: 35px;
    text-align: left;
    margin-bottom: 5px;
}

.textInputParticipants{
    display: flex;
    margin-left: 2%;
    margin-bottom: 5px;
}
.textInputParticipants p{
    margin-left: 2%;
    width: 90%;
    margin-bottom: 5px;
    text-align: left;
}

.textInputParticipants input{
    text-align: left;
    margin-left: 2%;
    margin-bottom: 5px;
    display: flex;
    height: 100%;
    width: 80%;
    border-radius: 0px;
    font-size: 25px;
    border-color: black;
    padding: 0px;
}

.textResumeParticipant p{
    /*font-size: 75%;*/
    
    font-size: clamp(0.5em, 0.5em + 2vw, 1.5em);
    padding-right: 3%;
    
}
.textResumeParticipant {
 
    padding-right: 2%;
    padding-bottom: 1%;
    width: 98%;
}