.textHome{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    width: 100%;
    height: 86%;
    max-height: 86%;
    align-items: center;

   
}

.textHome p{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    margin: 8px;
    font-size: 40px;
    text-align: center;
    color: rgb(13, 46, 87);
}
.textHome img{
    width: 50%;
    height: 50%;
    
   
}