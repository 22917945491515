
.tableResume{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    user-select: none;
    width: 100%;
    
    
}

.tableResume thead{
    align-content: center;
    text-align: center;
    /*font-size: 16px;*/
    border:1px;
    font-size: clamp(0.5em, 0.5em + 2vw, 1.5em);
}
.tableResume tbody{
    align-content: center;
    text-align: center;
    border-bottom: 1px;
    border: 1px;
    /*font-size: 15px;*/
    font-size: clamp(0.5em, 0.5em + 2vw, 1.5em);
    border-bottom: 1px solid #000;
    
}



table {
    border-collapse: collapse;
    border-spacing: 2px;
    border-color: black;
}

.inputEditTable input{
    display: flex;
    justify-content: space-around;
    margin-left: 0px;
    height: 100%;
    width: 100%;
    border-radius: 0px;
    font-size: 18px;
    text-align: center;
    border-color: black;
    padding: 0px;
}
.footerTable{
    width: 98%;
    margin: 1%;
    margin-bottom: 0;
   
}
.footerTable button{
    width: 40%;
    height: 90%;
    background-color: #7e87eb;
    cursor: pointer;
    margin: 0%;
    border: none;
    border-radius: 10px;  
    align-items: center;
    align-content: center;
    text-align: center;
    /*font-size: 90%;*/
    font-size: clamp(0.01em, 0.01em + 2.2vw, 1.2em);
    color: black;
}
.buttonDel{
    width: clamp(5.3em, 5.3em + 4vw, 5.3em);;
    /*background-color: #7e87eb;*/
    background-color: transparent;
    cursor: pointer;
    margin: 0%;
    border-width: 3.4px;
    border-color: #983B3B;
    border-radius: 10px; 
    border-style: solid; 
    align-items: center;
    align-content: center;
    text-align: center;
    /*font-size: 90%;*/
    font-size: clamp(0.01em, 0.01em + 2.2vw, 1.5em);
    color: #983B3B;
    margin-top: 0.6em;
    margin-bottom: 0.3em;
    padding: 0;
    
}
.checkboxTable{
    display: flex;
    width: 100%;
    padding: 1%;
    min-height: 10%;
    max-height: 50%;
    align-items: center;
    border-bottom: 1px ;
    font-size: 1em;
    margin-top: 1% ;
    margin-bottom: 1%;  

}

.checkboxTable label{
   width: 4%;
   text-align: left;
   margin-right: 10px;
   
}
.checkboxTable input[type="checkbox"]{
    appearance: none; 
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 2px solid #333; 
    border-radius: 4px; 
    outline: none; 
    width: 15px;
    height: 15px;
    align-items: center;
    text-align: center;
    margin: 0;
    /*background: #BFABAB;*/
}

.checkboxTable input[type="checkbox"]:checked {
    background-color: #333; /* Cor de fundo quando marcado */
    border-color: #333; /* Cor da borda quando marcado */
}

.checkboxTable p{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: clamp(0.1em, 0.1em + 2vw, 1em);
    font-weight: bold
}



