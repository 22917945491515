.footer{
    display: flex;
    justify-content: space-around;
    width: 100%;
    background-color: #3652E1;
    height: 7%;
    vertical-align: middle;
    align-items: center;
    align-content: center;
    bottom: 0px;
    
}

.footer button{
    width: 20%;
    height: 70%;
    background-color: #C37873;
    cursor: pointer;
    margin: 0.5%;
    border: none;
    border-radius: 10px;  
    align-items: center;
    align-content: center;
    /*font-size: 90%;*/
    font-size: clamp(0.01em, 0.01em + 3.5vw, 1.5em);
    color: black;
}

.buttonResume{
    display: flex;
    justify-content: space-around;
    width: 100%;
    background-color: #AFB2D2;
    height: 7%;
    vertical-align: middle;
    align-items: center;
    align-content: center;
    bottom: 0px;
}

.buttonResume button{
    border-radius: 10px; 
    width: 40%;
    height: 70%;
    cursor: pointer;
    margin: 1%;
    border: none;
    /*font-size: 100%;*/
    font-size: clamp(0.5em, 0.5em + 4vw, 1.5em);
    /*background-color: #e0e1ee;
    /*background-color: #9596af;*/
}
