.title{
    width: 100%;
    background-color: #3652E1;
    height: 7%;
    display: flex;
    align-items: center;
}
.title h1{
    color: #FFFFFF;
    width: 100%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    margin: 0;
    border: 0;
    text-align: center;
    align-items: center;
    font-size: clamp(1em, 1em + 4vw, 3em);
}
.subtitle{
    display: flex;
    width: 100%;
    background-color:  #AFB2D2;
    height: 4%;
    vertical-align: middle;
    align-items: center;
    align-content: center;
    text-align: center;
    
}
.subtitle h1{
    color: #FFFFFF;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: rgb(42, 42, 46);
    width: 100%;
    margin: 0;
    border: 0;
    text-align: center;
    align-items: center;
    padding: 0px;
    font-size: clamp(0.4em, 0.4em + 2vw, 1.2em);

}
.instruction{
    display: flex;
    width: 100%;
   /* background-color:  #AFB2D2;*/
    height: 2%;
    vertical-align: middle;
    align-items: center;
    align-content: center;
    text-align: center;
    margin-top: 1%;
    /*margin-bottom: 1%;*/
    
}
.instruction h1{
    color: #FFFFFF;
    /*font-family: Verdana, Geneva, Tahoma, sans-serif;*/
    font-family: Arial, Helvetica, sans-serif;
    color: rgb(42, 42, 46);
    width: 100%;
   /* margin: 4%;*/
    border: 0;
    text-align: left;
    align-items: center;
    padding: 0px;
    font-size: clamp(0.1em, 0.1em + 2vw, 1em);

}

.subtituleResult{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    width: 100%;
    height: 5%;
    text-align: center;
    vertical-align:text-bottom;
    margin: 0;
    
}