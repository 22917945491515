.divInputExpenses{
    display: flex;
    flex-direction: column;
    width: 35%;
    height: 100%;   
    margin-left: 5%;
    
}

.textInputExpenes{
    display: flex;
}
.divInputExpenses p{
    /*font-size: 10px;*/
    font-size: clamp(0.3em, 0.3em + 0.5vw, 0.5em);
    width: 40%;
    margin: 0;
    margin-right: 5%;
}


.divInputExpenses input{
    appearance: none; 
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #0e0e0e; 
    border-radius: 4px; 
    outline: none; 
    width: 40%;
    height: 25px;
    align-items: center;
    text-align: center;
    margin: 0;
    margin-right: 5%;
    padding: 0px;
    font-size: 13px;
    background: #C8C7DB;;
}


.div_input_checked{
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.50);
    background: #BFABAB;
    padding: 5px;
    padding-top: 10px;
    padding-bottom: 20px;
    margin: 8px;
    margin-bottom: 5px;
    font-family: Arial, Helvetica, sans-serif;
    /*font-size: 120%;*/
    font-size: clamp(1em, 1em + 2vw, 2em);
    height: 80%;
    overflow-y: auto;
}
.div_input_checked_block{
    display: flex;
    background: #BFABAB;
    padding: 1%;
    min-height: 10%;
    /*height: 10%;*/
    max-height: 30%;
    
    align-items: center;
    border-bottom: 1px solid #000;
    

}

.div_input_checked_block label{
   width: 7%;
   text-align: left;
   margin-right: 5px;
   
}
.div_input_checked_block input[type="checkbox"]{
    appearance: none; 
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 2px solid #333; 
    border-radius: 4px; 
    outline: none; 
    width: 20px;
    height: 20px;
    align-items: center;
    text-align: center;
    margin: 0;
    background: #BFABAB;
}

.div_input_checked_block input[type="checkbox"]:checked {
    background-color: #333; /* Cor de fundo quando marcado */
    border-color: #333; /* Cor da borda quando marcado */
  }
